module.exports = [{
      plugin: require('/home/travis/build/SoraYama/sorayama.github.io/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-149867966-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/home/travis/build/SoraYama/sorayama.github.io/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":690,"backgroundColor":"#f7f0eb"},
    },{
      plugin: require('/home/travis/build/SoraYama/sorayama.github.io/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/travis/build/SoraYama/sorayama.github.io/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SoraYama's personal blog","short_name":"SoraYama","start_url":"/","background_color":"#f7f7f7","theme_color":"#191919","icon":"src/images/icon.png","display":"standalone"},
    },{
      plugin: require('/home/travis/build/SoraYama/sorayama.github.io/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
